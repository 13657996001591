// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore"
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCbMuTgr7r3sZ2ZlO7YnKT7U1duczFmIXw",
  authDomain: "epiito.firebaseapp.com",
  databaseURL: "https://epiito-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "epiito",
  storageBucket: "epiito.appspot.com",
  messagingSenderId: "729810498865",
  appId: "1:729810498865:web:02b756b13c4d8e1946ac10"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage();
