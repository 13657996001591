<template>
  <v-menu
    offset-y
    nudge-bottom="22"
    min-width="175"
    left
    :elevation="$vuetify.theme.dark ? 9 : 8"
  >
    <!-- Activator -->
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs"
           class="d-flex align-center"
           v-on="on">
        <v-img :src="$vuetify.theme.dark ? require('@/assets/images/misc/triangleDown-White.png')
                : require('@/assets/images/misc/triangleDown-Black.png')"
               :alt="$i18n.locale"
               height="14px"
               width="22px"
               class="me-2"></v-img>
        <span v-if="$vuetify.breakpoint.smAndUp">{{ currentCompany.company.name  }}</span>
      </div>
    </template>

    <!-- Options List -->
   <v-list>
      <v-list-item-group
        :value="currentCompany.company.name"
        @change="updateActiveCompany"
      >
        <v-list-item
          v-for="company in companies"
          :key="company.company.name"
          :value="company.company.name"
                     >
          <v-list-item-title>{{ company.company.name }}</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>


<!--localStorage.setItem('company', JSON.stringify(logincompany))-->
<!--localStorage.getItem('companiesAndRoles', JSON.stringify(companiesAndRolesCollection))-->
<script>
import useAppConfig from '@core/@app-config/useAppConfig'
import router from '../../../../router'

export default {    
    methods: {
      updateActiveCompany: function (newCompany) {
      if (newCompany == undefined) {
        console.log("undefined company?")
        return
      }
      const found = this.companies.find(element => element.company.name === newCompany)
      this.currentCompany = found
      localStorage.setItem('company', JSON.stringify(found))

      router.push('/').catch(err => { })
      }
    },
    data() {
      return {
        currentCompany: JSON.parse(localStorage.getItem('company')),
        companies: JSON.parse(localStorage.getItem('companiesAndRoles')),
      }
    },
    beforeMount(){
      this.currentCompany =  JSON.parse(localStorage.getItem('company'))
      this.companies = JSON.parse(localStorage.getItem('companiesAndRoles'))
    },
}
</script>
