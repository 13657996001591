import ability from './ability'

export const canNavigate = to => to.matched.some(route => ability.can(route.meta.action || 'read', route.meta.resource))

//export const canNavigate = to => {

//  if (to.matched.some(route => route.path == "/apps/user/view/:id")) {
//    const company = localStorage.getItem('company')
//    const asd = JSON.parse(company)
//    const q2we = asd.membership.path;

//    const asd3 = to.matched.some(route => route.fullPath == "/apps/user/view/" + asd.membership.path)
//    return true;
//  }

//  to.matched.some(route => ability.can(route.meta.action || 'read', route.meta.resource))


//}


export const _ = undefined
