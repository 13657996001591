import { canNavigate } from '@/plugins/acl/routeProtection'
import Vue from 'vue'
import VueRouter from 'vue-router'
import apps from './apps'
import dashboard from './dashboard'
import pages from './pages'
import userInterface from './user-interface'
import { db, auth } from '../Firebase/Firebase.service'
import { onAuthStateChanged } from "firebase/auth";

Vue.use(VueRouter)

const routes = [
  // ? We are redirecting to different pages based on role.
  // NOTE: Role is just for UI purposes. ACL is based on abilities.
  {
    path: '/',
    redirect: to => {
      const userData = JSON.parse(localStorage.getItem('userData'))
      const userRole = userData && userData.role ? userData.role : null

      if (userRole === 'admin' || userRole === 'manager' || userRole === 'viewer') {
        return { name: 'dashboard-crm' }
      }
      if (userRole === 'client') return { name: 'page-access-control' }

      return { name: 'auth-login', query: to.query }
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error404.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      title: 'Epiito - Error',
      requiresAuth: false,
    },
  }, //error
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
      title: 'Epiito - Login',
      requiresAuth: false,
    },
  }, //login
  {
    path: '/register',
    name: 'auth-register',
    component: () => import('@/views/Register.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
      title: 'Epiito - Register',
      requiresAuth: false,
    },
  }, //register
  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/ForgotPassword.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
      title: 'Epiito - Forgot password',
      requiresAuth: false,
    },
  }, //forgotpassword
  {
    path: '/not-authorized',
    name: 'misc-not-authorized',
    component: () => import('@/views/pages/miscellaneous/NotAuthorized.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      title: 'Epiito - not authorized',
    },
  }, //not authorized
  {
    path: '*',
    redirect: 'error-404',
    title: 'Epiito - Error',
    requiresAuth: false,
  }, //error


  //pages
  { //accountsettings
    path: '/pages/account-settings',
    name: 'page-account-settings',
    component: () => import('@/views/pages/account-settings/AccountSettings.vue'),
    meta: {
      layout: 'content',
      requiresAuth: true,
      resource: 'account',
      action: 'read',
    },
  }, //account-settings
  //apps
  {
    path: '/apps/downloads',
    name: 'downloads',
    component: () => import('@/views/apps/downloads/Downloads.vue'),
    meta: {
      layout: 'content',
      title: 'Epiito - Downloads',
      requiresAuth: true,
      resource: 'buildsDownload',
      action: 'read',
    },
  },//downloads

  //
  //* ��� User ������������������
  //

  {
    path: '/apps/user/list',
    name: 'apps-user-list',
    component: () => import('@/views/apps/user/user-list/UserList.vue'),
    meta: {
      layout: 'content',
      title: 'Epiito - User list',
      requiresAuth: true,
      resource: 'users',
      action: 'read',
    },
  },//apps/user/list
  {
    path: '/apps/user/view/:id',
    name: 'apps-user-view',
    component: () => import('@/views/apps/user/user-view/UserView.vue'),
    meta: {
      layout: 'content',
      title: 'Epiito - User view',
      requiresAuth: true,
      resource: 'users',
      action: 'read',
    },
  },//apps/user/view/:id


  //
  //* ��� Projects ������������������
  //

  {
    path: '/apps/projects/list',
    name: 'apps-projects-list',
    component: () => import('@/views/apps/projects/project-list/ProjectList.vue'),
    meta: {
      layout: 'content',
      title: 'Epiito - Project list',
      requiresAuth: true,
      resource: 'project',
      action: 'read',
    },
  }, //apps/projects / list
  {
    path: '/apps/projects/view/:id',
    name: 'apps-project-view',
    component: () => import('@/views/apps/projects/project-view/ProjectView.vue'),
    meta: {
      layout: 'content',
      title: 'Epiito - Project view',
      requiresAuth: true,
      resource: 'project',
      action: 'read',
    },
  }, //apps/projects / view /: id

  //dashboard
  { //crm
    path: '/dashboards/crm',
    name: 'dashboard-crm',
    component: () => import('@/views/dashboards/crm/Crm.vue'),
    meta: {
      layout: 'content',
      title: 'Epiito - Dashboard',
      requiresAuth: true,
      resource: 'buildsDownload',
      action: 'read',
    },
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})


//set document title
const DEFAULT_TITLE = 'Epiito'

router.beforeEach((to, _, next) => {

  const userData = localStorage.getItem('userData')
  const accessToken = localStorage.getItem('accessToken')
  const userAbility = localStorage.getItem('userAbility')
  const company = localStorage.getItem('company')
  const companiesAndRoles = localStorage.getItem('companiesAndRoles')

  //localStorage.clear('userData')

  onAuthStateChanged(auth, (user) => {
    //check if all data is stored
    const isLoggedIn = userData && accessToken && userAbility && user && company && companiesAndRoles

    if (to.matched.some((res) => res.meta.requiresAuth) && !isLoggedIn) {
      return next({ name: 'auth-login'})
    }

    if (!canNavigate(to)) {
      // Redirect to login if not logged in
      if (!isLoggedIn) return next({ name: 'auth-login'})

      // If logged in => not authorized

      if (to.name == 'misc-not-authorized') {
        return next()
      }
      return next({ name: 'misc-not-authorized' })

      // return next({ name: 'misc-not-authorized' })
    }

    // Redirect if logged in
    if (to.meta.redirectIfLoggedIn && isLoggedIn) {
      next('/')
    }

    return next()



  })

})

export default router
